import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import cabeceraCud from '../../assets/logo.png';
import { useSelector, useDispatch } from 'react-redux';
import { fechaActualBoliviaOnly } from '../../util/valueCalculator';
import { formatearFechaSimple } from '../../util/customFormatter';

const CustomPrintModal2 = ({ show, handleClose, title1, title2, title3, content, copia }) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const { nombre, usuario,rolActivo, roles,id } = useSelector(state => state.sesion);


    useEffect(() => {
        if (show) {
            generatePdf();
        }
    }, [show, content]);

    const generatePdf = () => {
        const doc = new jsPDF('p', 'pt', 'letter'); // Establecer tamaño de hoja a carta

        const marginLeftRight = 55; // Márgenes izquierdo y derecho
        const marginTop = 55; // Margen superior
        const marginBottom = 75; // Ajustar el margen inferior para bajar el pie de página
        const headerHeight = 60; // Altura de la cabecera

        const addWatermark = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setFontSize(72);
            doc.setTextColor(240, 240, 240); // marca de agua
            doc.setFont("helvetica", "bold");
            doc.text("COPIA", pageWidth / 2, pageHeight / 2, {
                angle: 45,
                align: 'center'
            });
        };

        const addHeader = (doc, pageNumber) => {
            const pageWidth = doc.internal.pageSize.getWidth();
        
            // Obtener las propiedades de la imagen
            const imgProps = doc.getImageProperties(cabeceraCud);
            const imgWidth = imgProps.width;
            const imgHeight = imgProps.height;
        
            // Calcular el tamaño de la imagen para que sea un cuadrado perfecto
            const maxDimension = Math.min(headerHeight, headerHeight); // Mantener la imagen como un cuadrado
            const scale = maxDimension / Math.max(imgWidth, imgHeight); // Escalar proporcionalmente
            const scaledWidth = imgWidth * scale;
            const scaledHeight = imgHeight * scale;
        
            // Dibujar la imagen alineada a la izquierda
            doc.addImage(
                cabeceraCud,
                'PNG',
                marginLeftRight, // Posición horizontal (alineada a la izquierda)
                marginTop, // Posición vertical
                scaledWidth,
                scaledHeight
            );
        
            // Agregar el texto "Colegio de Arquitectos de Tarija" a la derecha
            doc.setFontSize(16);
            doc.setFont("helvetica", "bold");
            doc.setTextColor(0, 0, 0); // Texto negro
            doc.text(
                "COLEGIO DE ARQUITECTOS TARIJA", 
                marginLeftRight + scaledWidth+ 15, // Posición horizontal (alineada a la derecha)
                marginTop + scaledHeight / 2, // Centrar verticalmente con respecto a la imagen
                { align: 'left' }
            );
            doc.setFont("times", "normal");
            // Agregar el número de página si es mayor a 0
            doc.setFontSize(12);
            if (pageNumber > 0) {
                doc.text(
                    `Página ${pageNumber}`, 
                    pageWidth - marginLeftRight, 
                    marginTop + headerHeight + 20, 
                    { align: 'right' }
                );
            }
        };


        const addFooter = (doc) => {
            const pageHeight = doc.internal.pageSize.getHeight();
            const pageWidth = doc.internal.pageSize.getWidth();

            doc.setDrawColor(0, 50, 0); // Verde más oscuro
            doc.setLineWidth(1);
            doc.line(marginLeftRight, pageHeight - marginBottom - 10, pageWidth - marginLeftRight, pageHeight - marginBottom - 10);

            doc.setFontSize(12);
            doc.setTextColor(0, 50, 0); // Verde más oscuro
            doc.setFont("times", "normal");
            doc.text('Calle La Madrid # 615 casi Esq. Junín - Celular.: (591-4)6640066 - cat.tarija@gmail.com', pageWidth / 2, pageHeight - marginBottom + 20, { align: 'center' });
            doc.text('Tarija - Bolivia', pageWidth / 2, pageHeight - marginBottom + 30, { align: 'center' });
            doc.setFontSize(10);
            doc.text(
                `Impreso el: ${formatearFechaSimple(fechaActualBoliviaOnly())} | Usuario: ${nombre}`, 
                pageWidth - marginLeftRight, 
                pageHeight - marginBottom + 40, 
                { align: 'right' }
            );
        };

        // Validaciones: Asegurar que los valores no sean null o undefined
        const validTitle1 = title1 || '';
        const validTitle2 = title2 || '';
        const validTitle3 = title3 || '';
        
        

        // Inicializa el número de página
        let pageNumber = 0;

        const addTitles = (doc) => {
            doc.setFontSize(16);
            doc.setFont("times", "bold");
            doc.setTextColor(0, 0, 0); // Asegurar que los títulos estén en negro
            // Validar que los títulos no sean nulos o vacíos
            if (validTitle1) {
                doc.text(validTitle1, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 30, { align: 'center' });
            }
           
            if (validTitle2) {
                doc.text(validTitle2, doc.internal.pageSize.getWidth() / 2, marginTop + headerHeight + 48, { align: 'center' });
            }
            doc.setFontSize(12);
            doc.setFont("times", "normal");
            if (validTitle3) {
                doc.text(validTitle3, marginLeftRight, marginTop + headerHeight + 65, { align: 'left' });
            }
        };


        // Draw watermark on the first page before any content if copia is true
        if (copia) {
            addWatermark(doc);
        }

        if (typeof content === 'string') {
            addHeader(doc, pageNumber);
            addTitles(doc);
            doc.text(content, marginLeftRight, marginTop + headerHeight + 80);
            addFooter(doc);
        } else {
            addHeader(doc, pageNumber);
            addTitles(doc);
            autoTable(doc, {
                head: content.head,
                body: content.body,
                startY: marginTop + headerHeight + 80, // Debajo de la cabecera y los títulos
                margin: { top: marginTop + headerHeight + 80, left: marginLeftRight, right: marginLeftRight, bottom: marginBottom +10},
                didDrawPage: function (data) {
                    pageNumber++;
                    addHeader(doc, pageNumber);
                    if(pageNumber>1){addTitles(doc); }// Asegurar que los títulos aparezcan en cada página
                    if (copia) addWatermark(doc);
                    addFooter(doc);
                },
                styles: { 
                    fontSize: 9,
                    lineColor: [0, 0, 0],
                    lineWidth: 0.5,
                },
                headStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                },
                bodyStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    lineWidth: 0.5,
                    lineColor: [0, 0, 0],
                },
                pageBreak: 'auto', // Permitir que el contenido salte a nuevas páginas
             rowPageBreak:'avoid'
            });
        }

        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfUrl);
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {pdfUrl && (
                    <iframe src={pdfUrl} width="100%" height="500px" title="Vista previa del Recibo"></iframe>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomPrintModal2;
