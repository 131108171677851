import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAfiliados } from '../../reducers/afiliado_slice';
import Table from 'react-bootstrap/Table';
import CustomPrintModal from '../UI/CustomPrintModal1';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { fetchSituaciones } from '../../reducers/situacion_slice';
import { apellidosNombreFullPersona, formatearFechaSimple } from '../../util/customFormatter';
import { fechaActualBolivia, fechaActualBoliviaOnly, formatearFecha, getCuotaNombre } from '../../util/valueCalculator';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const AfiliadoReport = () => {
  const dispatch = useDispatch();
  const afiliados = useSelector(state => state.afiliado.afiliados || []);
  const situaciones = useSelector(state => state.situacion.situaciones || []);

  const [selectedSituaciones, setSelectedSituaciones] = useState([]);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [printContent, setPrintContent] = useState({ head: [], body: [], title2: '', title3: '' });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [includeSignatureLines, setIncludeSignatureLines] = useState(false);
  const [customTitle, setCustomTitle] = useState('');
  const [cumpleañeros, setCumpleañeros] = useState(false); // Estado para el checkbox "Cumpleañeros"
  const [cuotaFinal, setCuotaFinal] = useState("");
  const [typeReport, setTypeReport] = useState('cat');
  useEffect(() => {
    dispatch(fetchAfiliados());
    dispatch(fetchSituaciones());
  }, [dispatch]);

  useEffect(()=>{
    if(situaciones)
      { const situSelectedDefect = [...situaciones].filter((situ) => 
        situ.alias==="activo").map(situ => ({ value: situ.id, label: situ.nombre }));
        setSelectedSituaciones(situSelectedDefect)}
  },[situaciones])
  const handleFilterSituacionChange = selected => {
    setSelectedSituaciones(selected || []);
  };

  const getCuotasPendientes = (afiliado, fechaEvaluada) => {

    const calculateMonthDifference = (start, end) => {
      if (!start || !end) {
        // Si alguna de las fechas es inválida, devuelve 0
        return 0;
      }
      
      console.log("Fechas afiliado", apellidosNombreFullPersona(afiliado.persona,"ci"))
      console.log("Fechas comparadas", start, end)
      const [startYear, startMonth] = start.split('-').map(Number); // Convertir a números
      const [endYear, endMonth] = end.split('-').map(Number); // Convertir a números
  
      // Validar que las fechas sean números válidos
      if (isNaN(startYear) || isNaN(startMonth) || isNaN(endYear) || isNaN(endMonth)) {
        return 0; // Si alguna parte de la fecha no es válida, devuelve 0
      }
  
      return (endYear - startYear) * 12 + (endMonth - startMonth); // Diferencia en meses
    };
  
    // Calcular la diferencia en meses para CAT
    const diffCat = calculateMonthDifference(fechaEvaluada, afiliado.sig_cuota_cat);
  
    // Calcular la diferencia en meses para CAB
    const diffCab = calculateMonthDifference(fechaEvaluada, afiliado.sig_cuota_cab);
  
    return { diffCat, diffCab };
  };

  const afiliadosConPendientes = useMemo(()=>{
      
    if(Array.isArray(afiliados)){ 
      const hoy = new Date();
      let mesEvaluado = hoy.getMonth() + 1;
      let anioEvaluado = hoy.getFullYear();
      
      if (mesEvaluado > 11) {
          mesEvaluado = 0; // enero
          anioEvaluado += 1;
      }

      const mesString = String(mesEvaluado + 1).padStart(2, '0'); // +1 porque getMonth() es 0-indexado
      const fechaEvaluada = `${anioEvaluado}-${mesString}`;

      return [...afiliados].map((afiliado, index) => {
        const {diffCat, diffCab} = getCuotasPendientes(afiliado,fechaEvaluada);
        return {...afiliado, diffCat, diffCab}
      })
    } else {return []}
      
  },[afiliados]);


  const filteredAfiliados = useMemo(() => {
    let result = afiliadosConPendientes;
    if (cumpleañeros) {
      const today = new Date();
      const todayMonth = today.getMonth() + 1; // Mes actual (base 1)
      const todayDay = today.getDate(); // Día actual
      setCustomTitle("CUMPLEAÑEROS DEL DIA")
      result = result.filter(afiliado => {
        const fechaNacStr = afiliado.persona.fecha_nac;
        if (!fechaNacStr) return false; // Excluir si no hay fecha
  
        const [year, month, day] = fechaNacStr.split('-'); // Dividir por "-" para obtener partes
        if (!year || !month || !day) return false; // Excluir si el formato es incorrecto
  
        // Comparar el mes y el día con los valores actuales
        return parseInt(month, 10) === todayMonth && parseInt(day, 10) === todayDay;
      });
    }else {    
      return result.filter((afiliado, index) => {
            let matchesCuota=true, matchesSitu=true;
            if(cuotaFinal){ 
              const {diffCat, diffCab} = getCuotasPendientes(afiliado,cuotaFinal);
              matchesCuota = typeReport==="cat"? diffCat >= 0 : diffCab >= 0
            }
            if (selectedSituaciones.length > 0) {
              matchesSitu = selectedSituaciones.some(situ => situ.value === afiliado.id_situacion)
            }
            return matchesCuota && matchesSitu
        })}
  
    return result;
  }, [afiliadosConPendientes, cuotaFinal, selectedSituaciones, cumpleañeros]);
  

  const sortedAfiliados = useMemo(() => {
    const sorted = [...filteredAfiliados];
    sorted.sort((a, b) => {
      let aValue, bValue;
      if (sortConfig.key === 'num_reg') {
        aValue = parseInt(a.num_reg, 10);
        bValue = parseInt(b.num_reg, 10);
      } else {
        aValue = `${apellidosNombreFullPersona(a.persona)}`.toLowerCase();
        bValue = `${apellidosNombreFullPersona(b.persona)}`.toLowerCase();
      }
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [filteredAfiliados, sortConfig]);

  const handleSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleExport = () => {
    const exportData = sortedAfiliados.map((afiliado, index) => {
      const baseData = {
        'N°': index + 1,
        'C.I.': afiliado.persona.ci,
        'Situación': afiliado.situacion.nombre || 'N/A',
      };

      if (sortConfig.key === 'num_reg') {
        return {
          ...baseData,
          'N° Registro': afiliado.num_reg,
          'Nombre Completo': `${apellidosNombreFullPersona(afiliado.persona)}`,
        };
      } else {
        return {
          ...baseData,
          'Nombre Completo': `${apellidosNombreFullPersona(afiliado.persona)}`,
          'N° Registro': afiliado.num_reg,
        };
      }
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Afiliados');
    XLSX.writeFile(workbook, 'afiliados.xlsx');
  };

  const getPreviewContent = () => {
    const headRow = ['#', 'C.I.', 'Situación', 'Siguiente Cuota'];

    if (sortConfig.key === 'num_reg') {
      headRow.splice(1, 0, 'Núm. Registro', 'Nombre Completo');
    } else {
      headRow.splice(1, 0, 'Nombre Completo', 'Núm. Registro');
    }
    if (includeSignatureLines) {
      headRow.push('Firma'); // Agregar columna para firma
    }

    const bodyContent = sortedAfiliados.map((afiliado, index) => {
      const baseRow = [
        index + 1,
        afiliado.persona.ci,
        afiliado.situacion.nombre || 'N/A',
        typeReport==="cat" && `CAT: ${getCuotaNombre(afiliado.sig_cuota_cat)}`||
        typeReport==="cab" && `CAB: ${getCuotaNombre(afiliado.sig_cuota_cab)}`
      ];

      if (sortConfig.key === 'num_reg') {
        baseRow.splice(1, 0, afiliado.num_reg, `${apellidosNombreFullPersona(afiliado.persona)}`);
      } else {
        baseRow.splice(1, 0, `${apellidosNombreFullPersona(afiliado.persona)}`, afiliado.num_reg);
      }

      if (includeSignatureLines) {
        baseRow.push('_______________________'); // Espacio reservado para firma
      }

      return baseRow;
    });

    let subtitulo = ""

    return {
      head: [headRow],
      body: bodyContent,
      title2: customTitle || 'SIN TÍTULO',
      title3: new Date().toLocaleDateString(),
    };
  };

  const situacionOptions = situaciones.map(situacion => ({
    value: situacion.id,
    label: situacion.nombre,
  }));
  const handleCuotaChange = (e) => {
    const value = e.target.value;

    // Expresión regular para validar el formato "AAAA-MM"
    const regex = /^\d{4}-(0[1-9]|1[0-2])$/;

    if (!regex.test(value)) {
      Swal.fire("Formato inválido", "La fecha debe estar en el formato AAAA-MM (por ejemplo, 2025-04).", "error");
      setCuotaFinal(""); // Limpiar el campo si el formato es inválido
      return;
    }

    setCuotaFinal(value); // Actualizar el estado si el formato es válido
  };
  return (
    <div className="list-container list1">
      <h1 className="table-title">REPORTE DE AFILIADOS</h1>
      <div className="list-content">
      <div className='row'>
          <div className="col-md-2">
            <Form.Label>Tipo cuota</Form.Label>
                <Form.Control
                    as="select"
                    className="form-control"
                    size="sm"
                    value={typeReport}
                    onChange={(e)=>setTypeReport(e.target.value)}
              
                    >
                    <option value="cat">CAT</option>
                    <option value="cab">CAB</option>
            
                    </Form.Control>
                    </div>
          <Form.Group className='col-6  col-md-2'>
              <Form.Label>Cuota:</Form.Label>
              <Form.Control
                  type="text"
                  placeholder='AAAA-MM'
                    name='cuotaFinal'
                  value={cuotaFinal}
                  onChange={(e) => setCuotaFinal(e.target.value)}
                  onBlur={handleCuotaChange}
              />
          </Form.Group>
       
          <div className='col-6 col-md-2 mt-4'>
            <Select
              isMulti
              options={situaciones.filter(s=>s.alias && !s.alias.includes("preinscrito")).map(situ => ({ value: situ.id, label: situ.nombre }))}
              value={selectedSituaciones}
              onChange={handleFilterSituacionChange}
              placeholder="Filtrar por situación..."
              className="react-select-container"
              classNamePrefix="react-select"
            />
             
          </div>
          <div className="col-6 col-md-2 d-flex align-items-center">
            <input
              type="checkbox"
              id="cumpleañeros"
              checked={cumpleañeros}
              onChange={(e) => setCumpleañeros(e.target.checked)}
              className="form-check-input me-1"
            />
            <label htmlFor="cumpleañeros" className="form-check-label">
              Cumpleañeros
            </label>
          </div>
          <div className="col-6 col-md-4 mt-4">
            <input
              type="text"
              placeholder="Título del reporte"
              className="form-control"
              value={customTitle}
              onChange={(e) => setCustomTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="list-container-header d-flex justify-content-end">
          <button
            title="Incluir líneas de firma"
            onClick={() => setIncludeSignatureLines(!includeSignatureLines)}
            className="icon-button btn-transparent"
          >
            <i className={`bi ${includeSignatureLines ? 'bi-eye-slash' : 'bi-eye'}`}></i>
          </button>
          <button
            title="Imprimir"
            onClick={() => setShowPreviewModal(true)}
            className="icon-button btn-transparent"
          >
            <i className="bi bi-printer"></i>
          </button>
          <button
            title="Exportar a Excel"
            onDoubleClick={handleExport}
            className="icon-button btn-transparent"
          >
            <i className="bi bi-file-spreadsheet"></i>
          </button>
        </div>
        <div className="col-6 col-md-2 d-flex align-items-center">
            <input
              type="checkbox"
              id="firma"
              checked={includeSignatureLines}
              onChange={(e) => setIncludeSignatureLines(e.target.checked)}
              className="form-check-input me-1"
            />
            <label htmlFor="firma" className="form-check-label">
              Incluir casilla de firma
            </label>
          </div>
        {sortedAfiliados.length === 0 ? (
          <p>No hay afiliados disponibles.</p>
        ) : (
          <div className="table-container">
            <Table bordered hover size="sm">
              <thead style={{ backgroundColor: '#b2aea3' }}>
                <tr>
                  <th>#</th>
                  {sortConfig.key === 'num_reg' ? (
                    <>
                      <th onClick={() => handleSort('num_reg')} style={{ cursor: 'pointer' }}>
                        Número Registro {sortConfig.direction === 'asc' ? '↑' : '↓'}
                      </th>
                      <th onClick={() => handleSort('nombre_completo')} style={{ cursor: 'pointer' }}>
                        Nombre Completo
                      </th>
                    </>
                  ) : (
                    <>
                      <th onClick={() => handleSort('nombre_completo')} style={{ cursor: 'pointer' }}>
                        Nombre Completo {sortConfig.direction === 'asc' ? '↑' : '↓'}
                      </th>
                      <th onClick={() => handleSort('num_reg')} style={{ cursor: 'pointer' }}>
                        Número Registro
                      </th>
                    </>
                  )}
                  <th>C.I.</th>
                  <th>Fecha Nacimiento</th>
                  <th>Situación</th>
                  <th>Siguiente Cuota</th>
                  {/* <th>Estado Cuotas</th> */}
                </tr>
              </thead>
              <tbody>
                {sortedAfiliados.map((afiliado, index) => (
                  <tr key={afiliado.id}>
                    <td st>{index + 1}</td>
                    {sortConfig.key === 'num_reg' ? (
                      <>
                        <td>{afiliado.num_reg}</td>
                        <td>{`${apellidosNombreFullPersona(afiliado.persona)}`}</td>
                      </>
                    ) : (
                      <>
                        <td>{`${apellidosNombreFullPersona(afiliado.persona)}`}</td>
                        <td>{afiliado.num_reg}</td>
                      </>
                    )}
                    <td>{afiliado.persona.ci}</td>
                    <td>{formatearFechaSimple(afiliado.persona.fecha_nac)}</td>
                    <td>{afiliado.situacion.nombre || 'N/A'}</td>
                     <td>
                      {/* Mostrar las cuotas pendientes por separado */}
                    {typeReport==="cat" && <div>CAT: {getCuotaNombre(afiliado.sig_cuota_cat)}</div>}
                   {typeReport==="cab" &&   <div>CAB: {getCuotaNombre(afiliado.sig_cuota_cab)}</div>}
                    </td>
                    {/* <td>
                      <div>CAT: {afiliado.diffCat>0? `+${afiliado.diffCat}` :afiliado.diffCat}</div>
                      <div>CAB: {afiliado.diffCab}</div>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        <CustomPrintModal
          show={showPreviewModal}
          handleClose={() => setShowPreviewModal(false)}
          title1="LISTA DE AFILIADOS"
          title2={customTitle}
          title3={`Fecha: ${formatearFechaSimple(fechaActualBoliviaOnly())}  Cantidad de registros:${sortedAfiliados.length}`}
          content={getPreviewContent()}
        />
      </div>
    </div>
  );
};

export default AfiliadoReport;
